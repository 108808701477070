import React from "react";
import { graphql } from "gatsby";

import * as styles from "./index.module.css";

const Resume = ({ data }) => {
  const r = data.allResumeYaml.edges[0].node;
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.name}>
          <h1>{r.name}</h1>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.leftColumn}>
          <div className={styles.section}>
            <h2>Summary</h2>
            <p>{r.info.brief}</p>
          </div>
          <div className={styles.section}>
            <h2>Expertise</h2>
            <ul>
              {r.skills.list.map((skill, i) => (
                <li key={`skill${i}`}>
                  <strong>{skill.description}</strong>
                  {`: `}
                  {skill.summary}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.section}>
            <h2>References</h2>
            <ul>
              {r.references.map((ref) => (
                <li key={ref.name}>
                  <strong>{ref.name}</strong>
                  {`: `}
                  {ref.contact.map((c) => {
                    if (c.flavor === "email") {
                      return (
                        <a key={c.value} href={`mailto:${c.value}`}>
                          {c.value}{" "}
                        </a>
                      );
                    }
                    return c.value + ", ";
                  })}
                  <h4>{ref.flavor}</h4>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.rightColumn}>
          <div className={styles.section}>
            <h2>Let's Talk</h2>
            {/* <div className={styles.table}> */}
            {/*   <div className={styles.row}> */}
            {/*     <div className={styles.cell}> */}
            {/*       {r.contact.phone} */}
            {/*     </div> */}
            {/*     <div className={styles.cell}> */}
            {/*       <a href={`mailto:${r.contact.email}`}>{r.contact.email}</a> */}
            {/*     </div> */}
            {/*   </div> */}
            {/* </div> */}
            <p>
              {r.contact.phone}
              {`, `}
              <a href={`mailto:${r.contact.email}`}>{r.contact.email}</a>
            </p>
          </div>
          <div className={styles.section}>
            <h2>Work Experience</h2>
            {r.employment.history.map((job) => (
              <div key={job.employer}>
                <h3>{job.position}</h3>
                <h4>
                  <div>
                    <span className={styles.jobTime}>
                      {job.start} - {job.end == null ? "present" : job.end}
                    </span>
                    <span className={styles.employer}>{job.employer}</span>
                  </div>
                  {job.summary}
                </h4>
                <ul>
                  {job.highlights &&
                    job.highlights.map((h, i) => (
                      <li key={`hi${i}`} className={styles.star}>
                        {h}
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
          <div className={styles.section}>
            <h2>Education</h2>
            {r.education.history.map((s) => (
              <div key={s.institution}>
                <h3>{s.summary}</h3>
                <h4>{s.institution}</h4>
                <ul />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;

export const pageQuery = graphql`
  query {
    allResumeYaml {
      edges {
        node {
          name
          info {
            brief
          }
          contact {
            website
            phone
            email
          }
          location {
            address
            city
            region
            code
            country
          }
          skills {
            list {
              description
              summary
            }
          }
          references {
            name
            flavor
            contact {
              flavor
              value
            }
          }
          employment {
            history {
              employer
              url
              position
              summary
              start
              end
              keywords
              highlights
            }
          }
          projects {
            title
            summary
            start
          }
          education {
            history {
              institution
              title
              start
              end
              summary
            }
          }
        }
      }
    }
  }
`;
