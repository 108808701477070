// extracted by mini-css-extract-plugin
export var breakpointMedium = "(min-width: 600px) and (max-width: 959px)";
export var breakpointLarge = "(min-width: 960px)";
export var gutter = "35px";
export var gutterLarge = "65px";
export var marginTop = "0.2em";
export var fontSize = "18px";
export var fontFamily = "\"Barlow-Regular\", \"Helvetica Neue\", Helvetica, Arial, sans-serif";
export var headingFontFamily = "\"Barlow-Bold\", \"Helvetica Neue\", Helvetica, Arial, sans-serif";
export var container = "index-module--container--bjXqg";
export var content = "index-module--content--lqqkQ";
export var title = "index-module--title--UdoIc";
export var name = "index-module--name--AO30d";
export var leftColumn = "index-module--leftColumn--Al+AS";
export var section = "index-module--section--TU+n-";
export var rightColumn = "index-module--rightColumn--mVJSP";
export var star = "index-module--star--2L98b";
export var table = "index-module--table--eV+he";
export var row = "index-module--row--4c1sg";
export var cell = "index-module--cell--XPPA5";
export var employer = "index-module--employer--K36oT";
export var jobTime = "index-module--jobTime--3SNE7";